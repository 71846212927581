@media screen and (max-width: 768px) {
  html,
  body {
    overflow-x: hidden !important;
    width: 100% !important;
  }

  .sliderflex {
    flex-direction: column; /* Arrange items vertically */
    padding: 0px;
    gap: 5px;
  }
  .sliderleft {
    padding: 10px;
  }
  .sliderflex img {
    width: 100% !important;
    border-radius: 0px 0px 10px 10px !important;
    overflow: hidden !important;
    height: auto;
    margin-bottom: 10px !important; /* Add some space between image and content */
  }
  .sliderleft {
    order: 2; /* Change the order of content to appear below the image */
  }

  .sliderright {
    order: 1; /* Change the order of image to appear above the content */
  }
  .sliderflex h1 {
    font-size: 25px;
    text-align: center;
  }
  .sliderright img {
    border-radius: 0px 0px 5px 5px !important;
  }
  .typing-cursor {
    margin-left: 0px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
  .sliderflex button {
    margin-bottom: 30px;
  }
  .usernav {
    display: none;
  }

  

  .about-home {
    flex-wrap: wrap;
    padding: 10px;
  }
  .about-home * {
    width: 100%;
  }
  .about-home img {
    width: 100%;
    margin-top: 30px;
    height: 100%;
  }
  .about-prabhupada-home {
    margin-top: 30px;
  }
  .about-home-main {
    background-image: none;
    padding-bottom: 10px;

    height: auto !important;
  }
  .readmore {
    display: block;
    margin: auto;
    position: relative;
    top: 0px;
    left: 100px;
  }
  .activity-start {
    padding: 0px;
    margin-top: 30px;
  }
  .donationflex {
    flex-direction: column;
    padding: 0px;
    gap: 5px;
  }
  .donationflex button  {
    width: fit-content;
    text-wrap: nowrap;
  }
  .donationfooter {
    flex-direction: column;
  }
  .card {
    padding: 0px !important;
    box-shadow:  none !important;
  }
  .p-4 {
    padding: 12px !important;
  }
  .activity-main .bordered-h1::after {
    top: 70px;
  }
  .bordered-h1 {
    margin-top: 20px;
  }
  .activity-read {
    bottom: 40px;
  }
  .home-activity-card h2 {
    font-size: 20px;
    margin-top: 10px;
  }

  .about-home-main::after {
    display: none;
  }
  h1 {
    text-align: center;
    font-size: 2em !important;
    font-weight: 700;
    position: relative;
  }
  .about-home-main .bordered-h1::after {
    top: 40px !important;
  }

  .about-home-main h1 {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .home-activity-card img {
    height: 270px;
  }
  .home-activity-card {
    height: 760px;

  }
  .upcommming-festival {
    display: block;
  }
  .festivalbutton {
    position: relative;
    display: flex;
    margin: auto;
    left: 0px;
    width: fit-content;
    text-wrap: nowrap;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .upcommming-festival img {
    width: 100%;
    position: relative !important;
  }
  .upcomming-festival-main {
    padding: 10px;
    height: auto;
  }
  .upcomming-festival-main .bordered-h1::after {
    top: 5px !important;
  }
  .festival-date {
    top: 0px;
  }
  .calender-main {
    box-shadow: none  !important;
    position: relative;
  }
  .calendar-content {
    display: flex;
    flex-direction: column !important;
    height: fit-content !important;
    background-color: transparent !important;
  }
  .event {
  box-shadow: none !important;
  border: 1px solid black;
  }
  .react-calendar {
    width: 100% !important;
  }
  .event {
    height: fit-content;
  }
  .calender-main .bordered-h1::after {
    top: 12px !important;
  }
  .donation-home {
    display: flex;
    flex-direction: column;
  }
  .donationdiv:first-of-type {
    margin-top: 25px;
  }
  .donationdiv {
    padding: 10px;
  }
  .donation-home video,
  img {
    width: 100%;
    margin: none;
    display: block;
    margin: auto;
  }
  .donation-subtile {
    text-align: center;
  }
  .donationdiv {
    flex-direction: column;
  }
  .donation-image {
    margin: 0px;
  }
  .donationdiv-reverse .donation-image {
    order: 1;
  }
  .donationdiv-reverse .donation-text {
    order: 1;
  }
  .donation-main .bordered-h1::after {
    top: 5px !important;
  }
  .recent-donation-main {
    width: 100%;
  }
  .recent-donation-main .bordered-h1::after {
    top: 5px !important;
  }
  table {
    font-size: 12px;
  }
  .recent-donation-main {
    padding: 10px;
  }
  .guest-house {
    flex-direction: column;
    padding: 10px;
    margin-bottom: 60px;
  }
  .guest-house div {
    width: 100%;
  }
  .guest-house img {
    width: 100%;
    height: 270px;
    border-radius: 10px;
  }
  .room-rate {
    position: relative;
    top: -305px;
    border-radius: 10px 0px;
    font-weight: 600;
    color: white;
    padding: 5px;
    background-color: #dc3545;
    width: fit-content !important;
  }
  .room-info {
    height: 40px;
  }
  .container-guest-house .bordered-h1::after {
    top: 5px !important;
  }
  .main-contact-home {
    flex-direction: column;
  }
  .main-contact-home > * {
    width: 100%;
  }
  .contact-main .bordered-h1::after {
    top: -5px !important;
  }
  .contact-main {
    padding: 10px !important;
  }
  .booking-link {
    margin-bottom: -40px !important;
  }

  .mobilemenu {
    justify-content: space-around;
    align-items: center;
    position: fixed;
    display: flex;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    color: white;
    z-index: 999999 !important;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: all 0.3s ease;
  }

  .mobilemenu a{
    width: 20%;
    transition: all 0.3s ease;
    color: white;
    z-index: 999999 !important;
  }
  .mobilemenu div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 8px 0px 0px 0px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    border-radius: 20px;
  }

  .mobilemenu  div.active {
    background: #fff !important;
    color: #ff7e5f !important;
    border-radius: 10px;
    /* transform: translateY(-10px) !important; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important; */
  }

  .mobilemenu  img {
    width: 28px;
    height: 28px;
  }

  .mobilemenu  span {
    font-size: 14px;
    font-weight: bold;
  }

  .mobilemenu  div:hover {
    background: rgba(255, 255, 255, 0.2);
    /* transform: translateY(-5px); */
  }


  /* Other */
  .event {
    padding: 0px !important;
  }
  .event-details {
    padding: 0px;
  }
  .footer-phone span {
    text-wrap: wrap;
  }
  .footer-address-test {
    display: flex;
    flex-direction: column;
  }
  /* Submenu  */
  .submenu-mobile {
    position: absolute;
    bottom: 58px;
    background-color: #feb47bf0;
    border-radius: none;
    width: 90%;
  }
  .submenu-mobile a {
    color: #dc3545 !important;
  }
  .submenu-mobile  a {
    padding: 5px 10px;
    width: 100%;
    font-weight: 800;
    padding: 10px 7px;
  }
  .submenu-mobile a span 
  {
    font-size: 18px;
    font-weight: 800;
 }
  .submenu-mobile a:not(:last-child) {
    border-bottom: 1px solid white;
  }
  .main-container {
    margin-top: 10px !important;
  }
  #sidebar {
    border: none;
    margin: 0px;
    padding: 0px;
  }
  .width-50 {
    flex-direction: column;
    margin: 0px;
    padding: 0px;
  }
  .width-50 > * {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  .festival-components {
    display: none;
  }
  .festival-components-mobile {
    display: block;
  }
  .css-1sj2t5c {
    animation: none !important
    ;
  }
  .aboutiskcon {
    flex-direction:  column;
    padding: 0px !important;
    box-shadow: none !important;
  }
  .aboutiskcon img{
    width: 100%;
    height: auto;
  }
  .aboutiskconh3 {
    margin-bottom: 30px !important;
  }
  .aboutiskcon iframe{
    width: 100%;
    height: 270px !important;
  }
}
