@media screen and (max-width: 1400px) and (min-width: 768px) {
  .guest-house div {
    width: 100%;
  }

  .guest-house div img {
    width: 96%;
    border-radius: 10px;
    object-fit: cover; /* This will ensure the image covers the given height and width while maintaining its aspect ratio */
  }
  .guest-house div img:hover {
    transform: translateY(-10px);
    transition: 1s;
  }
  .sliderflex img {
    width: auto;
    border-radius: 7px;
    height: 420px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .sliderflex h1 {
    font-size: 2.5em;
  }

  .usernav .menu {
    display: flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    margin: 0px 10px;
    flex: 1;
  }
  .about-home  {
    height: 660px;
  }
  .home-activity-card {
    height: 750px;
  }
  .upcomming-festival-main {
    height: 650px;
  }
  
}


@media screen and (max-width: 1500px) and (min-width: 768px)  {
  .guest-house div {
    width: 100%;
  }
  .guest-house div img {
    width: 96%;
    border-radius: 10px;
    object-fit: cover; /* This will ensure the image covers the given height and width while maintaining its aspect ratio */
  }
  .guest-house div img:hover {
    transform: translateY(-10px);
    transition: 1s;
  }
  .sliderflex img {
    width: auto;
    border-radius: 7px;
    height: 460px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .sliderflex h1 {
    font-size: 3em;
  }

  .usernav .menu {
    display: flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    margin: 0px 10px;
    flex: 1;
  }
  .about-home  {
    height: 570px;
  }
  .home-activity-card {
    height: 750px;
  }
  .upcomming-festival-main {
    height: 650px;
  }
  
}
